<template>
  <div class="loan-end">
    <div class="icon">
      <img :src="endIcon" alt="">
    </div>
    <div class="notice">
      {{ msgData.content }}
    </div>
    <div class="bottom">
      <van-button
        round
        block
        type="info"
        color="linear-gradient(90deg, #FE9D02 0%, #F78017 100%)"
        style="margin-bottom: 20px;"
        @click="onSubmit(1)"
      >
        继续贷款
      </van-button>
      <van-button
        round
        block
        type="warning"
        plain
        style="margin-bottom: 20px;"
        @click="onSubmit(2)"
      >
        结束贷款
      </van-button>
    </div>
  </div>
</template>
<script>
import endIcon from '@/assets/loan/empty2@2x.png'
import { modifyLoanStatus } from '@/api/product'
export default {
  data() {
    return {
      msgData: {},
      endIcon
    }
  },
  mounted() {
    this.msgData = this.$route.params.msgData
  },
  methods: {
    async onSubmit(loanStatus) {
      await modifyLoanStatus({ loanStatus, orderId: this.$route.query.orderId })
      this.$store.dispatch('user/setSendLoanStatus', true)
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
.loan-end {
  padding-top: 70px;
  text-align: center;
  .icon img {
    width: 263px;
  }
  .notice {
    padding: 20px 10px 0 10px;
    font-size: 14px;
    color: #2E3135;
    line-height: 24px;
  }
  .bottom {
    padding: 32px 40px 0 40px;
  }
}
</style>
